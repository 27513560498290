import { Link } from "react-router-dom";
import { useGetBusinesses } from "../hooks/business-hooks";
import { Zoom } from "react-awesome-reveal";

export const AllySection = () => {
  const { data: stores, isLoading } = useGetBusinesses();

  if (isLoading) {
    return <p>Cargando nuestros aliados principales...</p>;
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-6">
            <div className="section-head text-center mt-8">
              <h3 className="h3style" data-title="Daily Best Sells">
                Principales aliados
              </h3>
              <div className="wt-separator bg-primarys"></div>
              <div className="wt-separator2 bg-primarys"></div>
            </div>
          </div>
        </div>

        <div className="row g-4">
          {stores?.map((store, index) => (
            <div className="col-12 col-md-4" key={index}>
              <Zoom className="ratio ratio-16x9">
                <div className="card h-100 flex-row overflow-hidden align-items-center shadow-lg rounded">
                  <div className="ms-2" style={{ width: "40%", flexShrink: 0 }}>
                    <img
                      className="img-fluid rounded"
                      src={store.logoUrl}
                      alt={store.name}
                      style={{
                        width: "100%",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center">
                    <div>
                      <h5 className="card-title fw-bold mb-2">{store.name}</h5>
                      <p
                        className="card-text small text-justify"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {store.description}
                      </p>
                    </div>
                    <Link
                      to={`/stores/${store.id}`}
                      className="btn btn-primary mt-3"
                    >
                      Ver tienda
                    </Link>
                  </div>
                </div>
              </Zoom>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
